@import url('https://fonts.googleapis.com/css2?family=Bahianita&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,300&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: rgb(255, 255, 184);
}

button, .Button {
  background: transparent;
  border: 3px solid #fff;
  color: rgb(255, 255, 184);
  display: inline-block;
  text-decoration: none;
  padding: 5px;
}
button:disabled, .Button:disabled {
  color: grey;
}
input {
  background: transparent;
  border: 0px;
  border-bottom: 3px solid #fff;
  padding: 5px;
  color: #fff;
}
input:focus {
  outline: none;
}