.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.Description {
  display: block;
  text-align: left;
}

.Trailer {
  margin-top: 50px;
}

.Section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.SteamLink {
  font-size: 50px;
  margin-top: 90px;
  margin-bottom: 50px;
  font-family: 'Bahianita', cursive;
}

.GymLink {
  font-size: 35px;
  margin-top: 0px;
  margin-bottom: 50px;
  font-family: 'Bahianita', cursive;
}

.MountRoukeLogo {
  max-width: 150px;
}