@import url(https://fonts.googleapis.com/css2?family=Bahianita&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: rgb(255, 255, 184);
}

button, .Button {
  background: transparent;
  border: 3px solid #fff;
  color: rgb(255, 255, 184);
  display: inline-block;
  text-decoration: none;
  padding: 5px;
}
button:disabled, .Button:disabled {
  color: grey;
}
input {
  background: transparent;
  border: 0px;
  border-bottom: 3px solid #fff;
  padding: 5px;
  color: #fff;
}
input:focus {
  outline: none;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.Description {
  display: block;
  text-align: left;
}

.Trailer {
  margin-top: 50px;
}

.Section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.SteamLink {
  font-size: 50px;
  margin-top: 90px;
  margin-bottom: 50px;
  font-family: 'Bahianita', cursive;
}

.GymLink {
  font-size: 35px;
  margin-top: 0px;
  margin-bottom: 50px;
  font-family: 'Bahianita', cursive;
}

.MountRoukeLogo {
  max-width: 150px;
}
